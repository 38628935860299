window.addEventListener('load', function () {
  const menuTrigger = document.getElementById('menu-trigger');
  if (menuTrigger) {
    menuTrigger.addEventListener('click', function () {
      const navigation = document.querySelector('.nav');
      if (navigation.classList.contains('menu-visible')) {
        navigation.classList.remove('menu-visible');

      } else {
        navigation.classList.add('menu-visible');
      }
    })
  }
  lazyloading();
});

function lazyloading() {
  let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
  let lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));

  if ('loading' in HTMLImageElement.prototype) {
    // if support the attribute "loading"
    console.log('lazy loading mode: attribute "loading"');
    lazyImages.forEach(lazyImage => {
      lazyImage.src = lazyImage.dataset.src;
    });

    // Intersection Observer for lazy backgrounds
    let lazyBackgroundObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });
    lazyBackgrounds.forEach(lazyBackground => {
      lazyBackgroundObserver.observe(lazyBackground);
    })
  } else {
    if ('IntersectionObserver' in window) {
      // if support Intersection Observer
      console.log('lazy loading mode: Intersection Observer');

      // lazy Images
      let lazyImageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;
            lazyImage.src = lazyImage.dataset.src;
            if (lazyImage.srcset) lazyImage.dataset.srcset;
            lazyImage.classList.remove('lazy');
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });
      lazyImages.forEach(lazyImage => {
        lazyImageObserver.observe(lazyImage);
      })

      // lazy backgrounds
      let lazyBackgroundObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            lazyBackgroundObserver.unobserve(entry.target);
          }
        });
      });
      lazyBackgrounds.forEach(lazyBackground => {
        lazyBackgroundObserver.observe(lazyBackground);
      })
    }
    else {
      // fall back to the more compatible method
      console.log('lazy loading mode: simple js lazy loading');
      let active = false;

      const lazyLoad = () => {
        if (!active) {
          active = true;

          setTimeout(function () {
            lazyImages.forEach(lazyImage => {
              if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0)
                && getComputedStyle(lazyImage).display !== 'none') {
                lazyImage.src = lazyImage.dataset.src;
                if (lazyImage.srcset) lazyImage.dataset.srcset;
                lazyImage.classList.remove('lazy');
                lazyImages = lazyImages.filter(image => image !== lazyImage);
              }
            });

            lazyBackgrounds.forEach(lazyBackground => {
              if ((lazyBackground.getBoundingClientRect().top <= window.innerHeight && lazyBackground.getBoundingClientRect().bottom >= 0)
                && getComputedStyle(lazyBackground).display !== 'none') {
                lazyBackground.classList.add('visible');
                lazyBackgrounds = lazyBackgrounds.filter(image => image !== lazyBackground);
              }
            });

            if (lazyImages.length === 0 && lazyBackgrounds.length === 0) {
              document.removeEventListener('scroll', lazyLoad);
              window.removeEventListener('resize', lazyLoad);
              window.removeEventListener('orientationchange', lazyLoad);
            }

            active = false;
          }, 200)
        };
      }

      lazyLoad();
      document.addEventListener('scroll', lazyLoad);
      window.addEventListener('resize', lazyLoad);
      window.addEventListener('orientationchange', lazyLoad);
    }
  }
}